.calculator {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(212, 183, 138, 0.1);
  border-radius: 24px;
  padding: 1.5rem;
  box-shadow: 0 20px 40px rgba(44, 36, 32, 0.05);
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.calculator::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    var(--chai-gold),
    transparent
  );
}

.calculator h3 {
  font-family: 'Karma', serif;
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 600;
  text-align: center;
  color: var(--chai-dark);
  margin-bottom: 0.3rem;
  padding: 0;
}

.calculator .subtitle {
  font-family: 'Karma', serif;
  font-size: 1rem;
  color: var(--chai-gray);
  text-align: center;
  margin-bottom: 2rem;
  padding: 0;
}

/* Mobile-first heading styles */
.calculator h3 {
  font-family: 'Karma', serif;
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 600;
  text-align: center;
  color: var(--chai-dark);
  margin-bottom: 0.3rem;
  padding: 0;
}

.calculator .subtitle {
  font-family: 'Karma', serif;
  font-size: 1rem;
  color: var(--chai-gray);
  text-align: center;
  margin-bottom: 2rem;
  padding: 0;
}

/* Input section */
.input-section {
  margin: 1.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
}

.input-section label {
  font-size: 1.1rem;
  color: var(--chai-gray);
}

.input-section input {
  width: 100px;
  height: 50px;
  border: 2px solid var(--chai-gold);
  border-radius: 12px;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  background: transparent;
  font-family: 'Karma', serif;
}

.input-section input:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(212, 183, 138, 0.2);
}

/* Recipe Results section */
.recipe-results {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.7)
  );
  border-radius: 16px;
  padding: 1rem;
  margin: 1.5rem 0;
}

.recipe-results h4 {
  font-family: 'Karma', serif;
  font-size: 1.8rem;
  font-weight: 500;
  color: var(--chai-dark);
  margin-bottom: 1.5rem;
  text-align: center;
}

.recipe-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  border-bottom: 1px solid rgba(212, 183, 138, 0.15);
}

.recipe-item:hover {
  background: linear-gradient(
    90deg,
    transparent,
    rgba(212, 183, 138, 0.05)
  );
}

.recipe-item:last-child {
  border-bottom: none;
}

.recipe-item span {
  font-size: 1.1rem;
  color: var(--chai-dark);
}

.recipe-item .amount {
  background: var(--chai-dark);
  color: var(--chai-cream);
  padding: 0.4rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
}

/* Instructions section */
.instructions {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.7)
  );
  border-radius: 16px;
  padding: 1rem;
  margin: 1.5rem 0;
}

.instructions h4 {
  font-family: 'Karma', serif;
  font-size: 1.8rem;
  font-weight: 500;
  color: var(--chai-dark);
  margin-bottom: 1.5rem;
  text-align: center;
}

.instructions ol {
  list-style-position: inside;
  padding: 0;
}

.instructions li {
  color: var(--chai-gray);
  margin-bottom: 0.75rem;
  padding: 0.5rem 0;
  font-size: 1rem;
  line-height: 1.5;
  border-bottom: 1px solid rgba(212, 183, 138, 0.15);
}

/* Container adjustments */
.container {
  padding: 1rem;
  width: 100%;
  max-width: 100%;
}

@media (min-width: 769px) {
  .container {
    padding: 2rem;
  }
}

/* Desktop styles */
@media (min-width: 768px) {
  .calculator {
    padding: 2rem;
  }

  .calculator h3 {
    font-size: 2.8rem;
  }

  .calculator .subtitle {
    font-size: 1.2rem;
  }

  .input-section {
    margin: 3rem 0;
  }

  .input-section input {
    width: 120px;
    height: 60px;
    font-size: 1.6rem;
  }

  .recipe-results,
  .instructions {
    padding: 1.5rem;
  }

  .recipe-item span {
    font-size: 1.2rem;
  }

  .instructions li {
    font-size: 1.1rem;
    padding: 0.75rem;
  }
}

/* Hide decorative elements on mobile */
@media (max-width: 767px) {
  .calculator::after,
  .floating-leaf {
    display: none;
  }
}

.number-input {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  border: 2px solid var(--chai-gold);
  border-radius: 12px;
  padding: 2px;
}

.number-input input {
  width: 60px;
  height: 46px;
  border: none;
  background: transparent;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  font-family: 'Karma', serif;
  padding: 0;
  margin: 0;
}

.number-input input:focus {
  outline: none;
}

.number-button {
  width: 46px;
  height: 46px;
  border: none;
  background: transparent;
  color: var(--chai-dark);
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
  border-radius: 10px;
}

.number-button:hover {
  background: rgba(212, 183, 138, 0.1);
}

.number-button:active {
  background: rgba(212, 183, 138, 0.2);
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .number-button {
    width: 42px;
    height: 42px;
  }

  .number-input input {
    width: 50px;
    height: 42px;
    font-size: 1.2rem;
  }
}