.header {
  text-align: center;
  margin: 2rem 1rem;
}

.logo {
  width: 80px;
  margin-bottom: 1rem;
}

.header h1 {
  font-family: 'Karma', serif;
  font-size: 3.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--chai-dark);
  letter-spacing: -0.02em;
}

.header h2 {
  font-family: 'Karma', serif;
  font-size: 1.8rem;
  font-weight: 400;
  color: var(--chai-gray);
  margin-bottom: 0.5rem;
  letter-spacing: 0.01em;
}

.hindi-text {
  font-family: 'Noto Sans Devanagari', sans-serif;
  font-size: 1.2rem;
  color: var(--chai-gray);
}

@media (max-width: 768px) {
  .header {
    margin: 1.5rem 0.5rem;
  }

  .header h1 {
    font-size: 2.8rem;
  }

  .header h2 {
    font-size: 1.4rem;
  }

  .hindi-text {
    font-size: 1rem;
  }
} 